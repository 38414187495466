.display-preview-container {
    border: 1px dashed #ccc;
    height: 128px;
    margin-top: 3px;
    overflow: hidden;
}

.main-list-display-preview-container {
    border: 1px dashed #ccc;
    height: 50px;
}