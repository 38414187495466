/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e6e6e6;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #a0a0a0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #797979;
}