*:not(path, polygon, rect, .spinner-border, .marquee-span) {
    color: black;
    font-family: 'Poppins' !important;
}

.marquee-span {
    // font-family: Arial, sans-serif !important;
    font-family: Roboto !important;
}

html,
body {
    height: 100%;
}

// body {
//     // overflow-y: scroll;
// }

.c-sidebar-brand-full {
    color: white;
    white-space: nowrap;
    font-size: 22px;
    text-shadow: 1px 1px 0.5px black;
}

.c-header {
    border-bottom: 1px solid white;
    // box-sizing: border-box;
}

// .c-footer {
//     // box-sizing: border-box;
// }

.c-header-brand {
    font-size: 22px;
}

.c-header-nav .c-header-nav-link .badge {
    color: white;
    text-shadow: none;
    padding: 6px 12px;
    margin-right: 10px;
    position: inherit;
    margin-top: 0px;
    text-transform: capitalize;
    font-weight: 500;
}

.c-main-container {
    width: 100%;
    height: 100%;
}

.c-app {
    background-color: white !important;
    // background: rgb(255, 255, 255) !important;
    // background: linear-gradient(153deg, rgba(255, 255, 255, 1) 0%, rgba(232, 232, 232, 1) 52%, rgba(204, 204, 204, 1) 100%) !important;
}

.c-sidebar {
    // background: #202123 !important;
    background-color: rgb(0, 0, 0, .7) !important;
}

.c-sidebar-nav-title {
    color: white !important;
    text-shadow: 1px 1px 1px black;
}

.c-sidebar-minimizer::before {
    filter: drop-shadow(2px 2px 0px rgb(0, 0, 0, 1));
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
    color: #ddd !important;
}

.c-sidebar .c-sidebar-nav-link {
    text-shadow: 1px 1px 1px black;
}

.c-sidebar .c-sidebar-nav-link>svg {
    margin-top: -3px;
    filter: drop-shadow(2px 2px 0px rgb(0, 0, 0, 1));
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: #ddd !important;
    // text-shadow: 1px 1px 1px black;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-active {
    background: #000000 !important;
    color: white !important;
}

.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon {
    color: white !important;
}

.c-sidebar .c-sidebar-brand {
    background: #202123 !important;
    border-bottom: 1px solid #3d3d3d;
    min-height: 57px;
}

.c-sidebar #db-banner-icon {
    border: 1px solid black;
}

.c-sidebar-nav-divider {
    height: 1px;
    transition: height 0.3s;
    background: #444444;
    margin: 0px 16px;
}

.c-sidebar .c-sidebar-minimizer {
    background: #202123 !important;
    border-top: 1px solid #3d3d3d;
    height: 50px;
}

.c-sidebar .c-sidebar-minimizer:hover {
    background: #2d2d2e !important;
}

.card-header {
    display: flex;
    align-items: center;
}

.c-card-header-action-buttons-container-right {
    margin-left: auto;
}

#root {
    height: 100%;
}

small,
.small {
    font-size: 90% !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Comfortaa;
}

.warning {
    color: orange !important;
}

.success {
    color: green !important;
}

label {
    cursor: pointer;
}

label:not(.d-block) {
    font-weight: 500; // medium
    margin-bottom: 5px;
}

span.required:after,
label.required:after,
small.required:after {
    content: "*";
    margin-left: 4px;
    //   color: red;
}

#form-container {
    background-color: white;
    padding: 100px;
    padding-top: 80px;
    box-shadow: 0px 3px 6px #00000029;
    width: 640px;
    margin: auto;
}

.form-error-message {
    margin-top: 5px;
    color: red !important;
    // margin-left: 30px;
}

.form-success-message {
    margin-top: 5px;
    color: #008a00 !important;
}

.submit-button-container {
    margin-top: 100px;
    text-align: center;
}

.submit-button-container button {
    min-width: 290px;
}

a:hover {
    text-decoration: none;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    background: rgba(229, 231, 242, 0.25);
    border-top: 1px solid #e5e7f2;
}

.footer img {
    //   width: 95px;
    height: 30px;
}



.rdt_TableCell button>.c-icon {
    color: black;
}

.datatable-checkbox {
    margin: 0px !important;
    width: 20px;
    height: 20px;
}

.datatable-delete-button {
    padding: 6px 5px 4px 5px;
    font-size: 6px;
}

a:not([role="menuitem"], .login-link, .c-header-nav-link, .c-header-brand) {
    color: #0f83aa !important;
}

.c-header-nav-link,
.c-header-brand,
.breadcrumb-item,
.breadcrumb-item.active,
.c-footer {
    color: black !important;
    text-shadow: 1px 1px 0px white;
}

.dropdown-header {
    text-shadow: 1px 1px 0px black;
}

.dropdown-header>svg {
    filter: drop-shadow(2px 2px 0px rgb(0, 0, 0, 1));
}

a:hover {
    color: #0fa1d1;
}

.rounded-50 {
    border-radius: 50px;
}

.db-button {
    flex: 1 1 auto;
    padding: 7px 20px;
    min-width: 160px;
    text-align: center;
    // text-transform: uppercase;
    // transition: 0.5s;
    // background-size: 200% auto;
    color: white;
    // box-shadow: 0 0 20px #eee;
    border-radius: 5px;
    // border: 1px solid white;
}

.db-layer-button {
    padding: 5px 10px;
    min-width: 100px;
    text-align: center;
    color: black;
    text-shadow: 1px 1px 0.5px #fff;
    border-radius: 5px;
}

.nav-item>svg,
.db-layer-button>svg {
    color: black;
    margin-top: 0px !important;
}

.nav-item>svg,
.db-layer-button>svg {
    filter: drop-shadow(2px 2px 0px rgb(255, 255, 255, 1));
}

.db-layer-button {
    background-color: transparent;
}

.db-layer-button-primary:hover:not([disabled]) {
    background-color: #f1f3f5 !important;
    color: black !important;
}

.db-layer-button-primary.active {
    background-color: #ffffff !important;
    color: black !important;
}

.db-layer-button-primary:disabled {
    color: rgb(126, 126, 126) !important;
}

.db-layer-button-primary:disabled>svg {
    color: rgb(126, 126, 126) !important;
}

.db-layer-button-light {
    padding: 5px 10px;
    min-width: 100px;
    text-align: center;
    color: black;
    text-shadow: 1px 1px 0.5px #fff;
    border-radius: 5px;
}

.db-layer-button-light {
    background-color: transparent;
}

.db-layer-button-light>span {
    color: black;
    text-shadow: 1px 1px 0px #fff;
}


.db-layer-button-light>svg {
    color: black;
    margin-top: 0px !important;
}

.db-layer-button-light>svg {
    filter: drop-shadow(2px 2px 0px rgb(255, 255, 255, 1));
}

.db-layer-button-light-primary:hover:not([disabled]) {
    background-color: rgb(255, 255, 255, .2) !important;
    color: black;
}

.db-layer-button-light-primary.active {
    background-color: rgb(255, 255, 255, .4) !important;
    color: black;
}

.db-layer-button-light-primary:disabled {
    color: rgb(126, 126, 126) !important;
}

.db-layer-button-light-primary:disabled>svg {
    color: rgb(126, 126, 126) !important;
}

.db-button>span,
.db-button>strong {
    color: white;
}

.btn-secondary {
    background-color: #747474 !important;
    border-color: #747474 !important;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
    background-color: #686868 !important;
    border-color: #686868 !important;
    color: white !important;
}

.modal-footer {
    border-top: none !important;
    justify-content: space-between;
}

.modal-footer .db-button {
    min-width: 120px !important;
}

.modal-footer div:first-child .db-button {
    margin-right: 10px;
    min-width: 100px !important;
}

.modal-footer div:last-child .db-button {
    margin-left: 10px;
}

.db-button>strong {
    color: white !important;
}

.db-button .c-icon {
    margin: 0px !important;
    margin-top: -3px !important;
    margin-right: 5px !important;
}

.db-button .spinner-border {
    margin-bottom: 2px;
    margin-right: 5px !important;
}

.db-button-neon {
    font-size: 20px !important;
    width: 230px;
    height: 70px;
    -webkit-animation: neonBgColor 20s infinite;
    animation: neonBgColor 10s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.db-button-neon:hover {
    color: white;
}

.db-button-black {
    background-color: $db-black-color;
    border-color: #202123;
}

.db-button-red {
    background-color: $db-red-color;
    border: 1px solid $db-red-color-darker;
}

.db-button svg,
.db-button-black svg,
.db-button-red svg,
.db-green-button svg,
.db-blue-button svg,
.db-red-button svg {
    color: white !important;
}

.db-button-black:hover:not([disabled]) {
    color: white !important;
    background-color: #000000;
}

.db-button-red:hover:not([disabled]) {
    color: white !important;
    background-color: $db-red-color-hover;
}

.db-green-button {
    background-color: $db-green-color !important;
    border: 1px solid $db-green-color-darker !important;
    font-size: 12px;
    min-height: auto !important;
    padding: 3px 10px !important;
}

.db-green-button:hover:not([disabled]) {
    color: white !important;
    background-color: $db-green-color-hover !important;
}

.db-blue-button {
    background-color: $db-blue-color !important;
    border: 1px solid $db-blue-color-darker !important;
    font-size: 12px;
    min-height: auto !important;
    padding: 3px 10px !important;
}

.db-blue-button:hover:not([disabled]) {
    color: white !important;
    background-color: $db-blue-color-hover !important;
}

.db-red-button {
    background-color: $db-red-color !important;
    border: 1px solid $db-red-color-darker !important;
    font-size: 12px;
    min-height: auto !important;
    padding: 3px 10px !important;
}

.db-red-button:hover:not([disabled]) {
    color: white !important;
    background-color: $db-red-color-hover !important;
}

.db-button-black:disabled,
.btn-secondary:disabled,
.button:disabled {
    opacity: 0.45;
    color: rgb(196, 196, 196) !important;
}

// .appointment-button-slot {
//     border: 1px solid #69cef1 !important;
//     color: #69cef1;
//     font-size: 16px;
// }

// .appointment-button-slot-disabled {
//     background-color: lightgray !important;
//     border: 1px solid lightgray !important;
//     color: rgb(34, 33, 33) !important;
//     font-size: 16px;
// }

// .appointment-button-slot:hover,
// .appointment-button-slot.active {
//     background-color: #44bfe7;
//     color: white;
// }

.telo-button-red {
    border: none !important;
    background-color: #dc3545;
    color: white;
}

.telo-button-red:hover:not([disabled]) {
    background-color: #e04757;
    color: white;
}

.btn-danger {
    border: none !important;
}

.telo-button-red:disabled {
    opacity: 0.45;
    color: white;
}

.telo-button-yellow {
    border: none !important;
    background-color: #ffdd51;
    color: white;
}

.telo-button-yellow:hover:not([disabled]) {
    background-color: #fad12e;
    color: white;
}

.telo-button-yellow:disabled {
    opacity: 0.45;
    color: white;
}

.telo-button-gray {
    border: none !important;
    background: #cccccc;
    color: black;
}

.telo-button-gray:hover:not([disabled]) {
    background: #c7c7c7;
    color: black;
}

.telo-button-gray:disabled {
    opacity: 0.45;
    color: black;
}

.telo-link-blue {
    font-size: 20px;
    background-color: #69cef1;
    color: black;
    padding: 12px 25px;
}

.telo-link-blue:hover {
    color: black;
}

.c-switch-slider {
    border: 1px solid #40c3ed;
}

.c-switch-slider:active {
    background-color: #44bfe7;
}

.c-switch-label.c-switch-lg {
    width: 60px;
    height: 39px;
}

.c-switch-label.c-switch-lg .c-switch-slider::before {
    width: 28px;
    height: 32px;
}

.webhook-trash-btn-icon:hover {
    cursor: pointer;
}

// form
#registration-form-header {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

#registration-form-sub-header {
    text-align: center;
}

input:focus,
select:focus,
textarea:focus {
    outline: none !important;
    border-color: #afafaf !important;
    box-shadow: 0 0 0px #969696 !important;
}

.form-control[readonly],
.form-control:disabled {
    background-color: #f7f7f7 !important;
}

button,
.cursor-pointer {
    cursor: pointer;
}

button:focus {
    outline: none !important;
    box-shadow: none !important;
}

a.active.nav-link {
    background-color: white !important;
    border-bottom: 1px solid white !important;
}

// .tab-content {
// background-color: white !important;
// border: 1px solid #c4c9d0;
// border-top: none;
// border-bottom-left-radius: 0.25rem;
// border-bottom-right-radius: 0.25rem;
// min-height: 300px;
// }

.form-properties-tab-container {
    margin-left: -21px;
    margin-right: -21px;
    margin-bottom: -21px;
}

.btn {
    // min-height: 10px;
    // color: #000000;
    min-height: 38px;
    color: #ffffff;
}

.form-control,
.react-tel-input .form-control {
    border-color: #d8dbe08e !important;
    padding: 8px 16px;
    // height: 38px !important;
    height: auto !important;
    font-size: 14px;

}

input[type="radio"],
input[type="checkbox"] {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.form-control-checkbox-inline-flex,
.form-control-radio-inline-flex {
    margin-right: 40px;
}

.header-image-preview-container {
    height: 150px;
    border: 1px dotted #cecfd18e;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px;
}

// .req-preview-container {
//     min-height: 130px;
// }

.req-image-preview-container {
    width: 30%;
    border: 1px dotted #cecfd18e;
    border-radius: 5px;
    padding: 5px;
    display: inline-block;
    margin-right: 3.3%;
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 10px;
}

.req-image {
    width: 100%;
    height: 130px;
    overflow: hidden;
}

.req-image-name {
    position: absolute;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.req-image-status {
    position: absolute;
    bottom: 3px;
}

.req-image-preview-container small {
    font-size: 10px !important;
}

.req-image-preview-details-container {
    width: 30%;
    border: 1px dotted #cecfd18e;
    border-radius: 5px;
    padding: 5px;
    display: inline-block;
    margin-right: 3.3%;
    position: relative;
    margin-bottom: 20px;
}

.c-progress {
    height: 10px;
}

.req-image-preview-container:hover,
.req-image-preview-details-container:hover {
    cursor: pointer;
    border: 1px dotted #69cef1;
    background-color: #d4eef7;
}

.delete-button {
    position: absolute;
    right: -10px;
    top: -10px;
    // border: 2px solid white !important;
    border-radius: 50%;
    // min-height: 30px;
}

.pdf-icon-container {
    width: 100% !important;
    height: 100%;
    display: flex;
    align-items: center;
}

.pdf-icon {
    margin: auto;
    width: 50px !important;
    height: 50px !important;
}

.req-image {
    width: 100%;
    height: 130px;
    overflow: hidden;
}

.btn-back-to-form,
.btn-submit-form {
    min-width: 40% !important;
}

.row-border-bottom-light {
    border-bottom: 1px dotted #ccc;
}

// custom sizes
.success-page-header {
    font-size: 48px;
    font-weight: bold;
}

.success-page-message {
    font-size: 20px;
}

.success-page-schedule {
    font-weight: bold;
}

.success-page-schedule #schedule-timezone {
    color: #8894ac;
}

.success-page-schedule-now-link {
    font-size: 26px;
}

.min-height-300 {
    min-height: 300px;
}

.min-height-200 {
    min-height: 200px;
}

// end custom sizes

// modified date picker css
.react-date-picker__wrapper {
    width: 100% !important;
    border: none !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.react-date-picker__inputGroup__input:invalid {
    background: none !important;
}

// end modified date picker css

// formik
.formik-textarea {
    height: 100px !important;
}

// end formik

// datatable
.DTCustom_overlay {
    background: rgba(255, 255, 255, 0.5) !important;
}

// .DTCustom_wrapper>header+div {
//     // overflow-x: auto;
//     overflow: unset;
// }

.DTCustom_wrapper>header {
    display: block !important;
    margin-bottom: 10px;
}

// datatable
.card-header h4 {
    margin: 0px;
}

// sticky
.sticky-site-header {
    position: -webkit-sticky;
    position: sticky;
    top: 57px;
}
// reactjs select
.global-react-select__control {
    min-height: 39px;
    border-color: #d8dbe08e !important;
}

.global-react-select__control--is-focused {
    border-color: #afafaf !important;
    box-shadow: none !important;
}

.global-react-select__value-container {
    padding: 4px 12px !important;
}


// datatable
.datatable-ccard-body {
    overflow-x: auto;
}

// .form-control-1 {
//     border-color: rgb(137 137 137 / 56%) !important;
//     font-size: "14px";
//     display: block;
//     width: 40%;
//     height: calc(3.5em + 0.75rem + 2px);
//     font-size: 0.875rem;
//     font-weight: 400;
//     line-height: 1.5;
//     border: 1px solid;
//     color: #768192;
//     background-color: #fff;
//     border-radius: 0.50rem;
//     margin: 10px 0px 0px 20px;

// }

// .btn-secondary-1 {
//     color: #000000;
//     background-color: #ced2d8;
//     padding: 5px 50px;
//     border-color: #202123;
//     margin: 0px 50px;
// }

// .db-button-black-1 {
//     background-color: #323232;
//     border-color: #202123;
//     padding: 5px 50px;
// }

// .text-right-1 {
//     text-align: left;
//     margin-left: 20px;

// }

// .db-button-black-2 {
//     background-color: #323232;
//     border-color: #202123;
//     padding: 5px 50px;
//     margin-left: 20px;
// }

// .bt1 {
//     background-color: #323232;
//     border-color: #202123;
//     min-height: 10px;
//     color: #ffffff;
//     margin: 0px 0px 0px 0px;
//     border-radius: 1.00rem;
// }

// JSON prettify
pre {
    // outline: 1px solid #ccc;
    // padding: 5px;
    margin: 15px 0px;
}

.string {
    color: green;
}

.number {
    color: darkorange;
}

.boolean {
    color: blue;
}

.null {
    color: magenta;
}

.key {
    color: red;
}

.opening-message {
    font-size: 18px;
    text-align: center;
    padding: 0px 30px;
}

.nav-badge-span {
    display: block;
    width: 25px;
    text-shadow: none;
    font-weight: 800;
}

.c-header-nav .dropdown-menu {
    min-width: 300px;
    // box-shadow: 1px 1px 5px #ccc;
    // border: 1px solid black;
    max-height: 70vh;
    overflow-y: auto;
}

.c-header-nav .dropdown-menu .dropdown-header {
    padding: 15px 20px;
    background: #2d2d2e !important;
    color: white !important;
}

.c-header-nav .dropdown-menu .dropdown-item {
    padding: 15px 20px;
}

.c-header-nav .dropdown-menu .dropdown-item:active {
    background: #818181;
    color: white;
}

.text-high-emphasis-inverse:hover {
    cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    color: #fff;
    background-color: #4970e5;
}

.status-icon {
    border-radius: 50%;

    & path {
        fill: white;
    }
}

.device-id-status,
.vertically-centered-contents {
    display: flex;
    justify-content: center;
    align-items: center;
}

.force-wrap-text {
    overflow-wrap: anywhere;
}

.font-weight-normal {
    font-weight: 500;
}

#confirm-modal {
    box-shadow: 2px 2px 13px #81b5f8;
    border-color: #4596ff
}

.rotate-left {
    transform: rotate(-90deg);
}

.rotate-right {
    transform: rotate(90deg);
}

.rotate-bottom {
    transform: rotate(180deg);
}

// react-draft-wysiwyg
.rdw-option-wrapper {
    height: 30px !important;
    min-width: 37px !important;
}
.rdw-editor-main {
    height: 275px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}