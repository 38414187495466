#landing-page .form-control {
    padding: 16px;
    border: 1px solid white !important;
    font-size: 16px;
}

#landing-page .btn {
    min-height: 58px;
}
#landing-page .btn span {
    margin: 0px !important;
    margin-right: 10px !important;
    vertical-align: inherit;
}

.login-link {
    font-size: 14px;
    color: white !important;
}

.opacity-bg {
    border: 1px solid white;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
}

.login-logo {
    height: 150px;
}