.modal-fullscreen>.modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    margin: 0px !important;
}

.modal-fullscreen>.modal-dialog>.modal-content {
    height: 100% !important;
}

@media (max-width: 992px) {
    .c-sidebar {
        --is-mobile: true;
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 1041 !important;
    }
}