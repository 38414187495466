// Variable overrides
$db-black-color: #323232;

$db-green-color: #00dfac;
$db-green-color-hover: #02c295;
$db-green-color-darker: #008a6ac2;

$db-blue-color: #00a3ff;
$db-blue-color-hover: #0593e6;
$db-blue-color-darker: #0173b4;

$db-red-color: #ff0036;
$db-red-color-hover: #da002f;
$db-red-color-darker: #bb0129;