.ml-btn-add {
    float: left;
}

.ml-image-preview-container {
    width: 100%;
    border: 1px dotted #cecfd18e;
    border-radius: 5px;
    padding: 5px;
    display: inline-block;
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 10px;
}

.ml-image-preview-container .req-image {
    width: 100%;
    height: 230px;
    overflow: hidden;
}

.ml-thumbnail {
    object-fit: contain;
    // object-fit: cover;
    // object-position: 0px 0px;
    // height: 50px;
    width: 250px;
    max-height: 100px;
}