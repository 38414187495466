.layout-main {
    display: flex;
    // flex-direction: row;
    width: 100%;
    height: 100%;
}

.layout-layers-settings-container {
    height: 100%;
    min-width: 0px;
    width: 0px;
    background-color: rgba(255, 255, 255, 0.6);
    // overflow-x: hidden;
    // overflow-y: unset;
}

.layout-layers-settings-container>* {
    visibility: hidden;
}

.layout-container {
    display: grid;
    width: 100%;
    height: 100%;
}

.layers-canvas-container {
    width: 100%;
    overflow: auto;
    // scroll-snap-type: both mandatory; // programmatically trigger it using jQuery
    // overflow: unset;
}

.layers-settings {
    padding: 20px 20px 0px;
    width: 400px;
    height: 100%;
    border-right: 1px solid white;
}

.layers-settings-content {
    margin: 20px 0px;
}

.layer-properties-name {
    font-size: 18px;
}

.layer-properties-header {
    color: white;
}

.layers-settings-layers-tab,
.layer-properties-container,
.devices-groups-tab,
.device-properties-container {
    border-top: 1px dashed rgba(255, 255, 255, 0.5);
    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}

.layer-align-buttons {
    text-align: left;
    font-size: 18px;
    padding: 8px 18px;
}

// .layer-properties .form-control {
//     padding: 10px 20px !important;
//     font-size: 18px !important;
// }

.layers-droppable-container,
.devices-device-groups-container {
    list-style: none;
    position: relative;
    padding: 0px !important;
    margin: 10px 0px !important;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px !important;
}

// .devices-device-groups-container {
//     // height: 250px;
//     overflow-y: scroll;
//     overflow-x: hidden;
//     padding: 10px 0px 5px 0px;
//     border-top: 1px dashed rgba(255, 255, 255, 0.5);
//     // border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
// }

.layer-properties-container {
    position: relative;
    padding: 0px !important;
    margin: 10px 0px !important;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px !important;
}

// ================================ layers list item 
.layers-reorder-item {
    list-style: none;
    user-select: none;
    width: 100%;
    position: relative;
    padding: 15px 18px;
    border: 1px solid rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, .8);
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-shrink: 0;
    font-size: 22px;
    pointer-events: auto;
    cursor: grab;
}

.layers-reorder-item-text {
    width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.layers-reorder-item:hover {
    border: 1px solid rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, .9);
}

.layers-reorder-item>.layers-reorder-item-drag-pointer,
.layers-reorder-item>.layers-reorder-item-edit {
    visibility: hidden;
}

.layers-reorder-item:hover>.layers-reorder-item-drag-pointer,
.layers-reorder-item:hover>.layers-reorder-item-edit {
    visibility: visible;
    opacity: 0.3;
}

.layers-reorder-item>.layers-reorder-item-drag-pointer:hover,
.layers-reorder-item>.layers-reorder-item-edit:hover {
    opacity: 1;
}

.layers-reorder-item-options,
.devices-reorder-item-options {
    pointer-events: auto;
    cursor: pointer;
    position: absolute;
    right: 0px;
    width: 40px;
    height: 68px;
    text-align: center;
}

.devices-reorder-item-options {
    width: 40px;
    height: 40px;
}

.layers-reorder-item-options-svg {
    opacity: 0.3;
    height: 66px !important;
    width: 24px !important;
}

.devices-reorder-item-options-svg {
    opacity: 0.3;
    height: 38px !important;
    width: 24px !important;
}

.layers-reorder-item-options:hover .layers-reorder-item-options-svg,
.devices-reorder-item-options:hover .devices-reorder-item-options-svg {
    opacity: 1;
}

.layers-reorder-item-drag-pointer {
    pointer-events: auto;
    cursor: grab;
    margin-right: 18px;
    margin-top: -16px;
    margin-bottom: -16px;
    position: absolute;
    right: 0px;
}

.layers-reorder-item-drag-pointer>svg {
    height: 66px !important;
    width: 24px !important;
}

.layers-reorder-item-indicator-icons {
    height: 10px;
    margin-top: -2px;
    margin-left: 5px;
    margin-right: 3px;
}

.layers-reorder-item-pre-icons {
    height: 65px;
    width: 24px;
    margin-right: 10px;
    margin-top: -16px;
    margin-bottom: -16px;
}

.layers-reorder-item-edit {
    pointer-events: auto;
    height: 65px;
    width: 24px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -16px;
    margin-bottom: -16px;
}

// ================================ END layers list item 

// ================================ START devices/groups list item 
.devices-groups-item {
    list-style: none;
    user-select: none;
    width: 100%;
    position: relative;
    padding: 6px 18px;
    border: 1px solid rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, .8);
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-shrink: 0;
    font-size: 16px;
}

.devices-groups-item-text {
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.devices-groups-item:hover {
    border: 1px solid rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, .9);
}

.devices-groups-item>.devices-groups-item-delete,
.devices-groups-item>.devices-groups-item-edit {
    visibility: hidden;
}

.devices-groups-item:hover>.devices-groups-item-delete,
.devices-groups-item:hover>.devices-groups-item-edit {
    visibility: visible;
    opacity: 0.3;
}

.devices-groups-item>.devices-groups-item-delete:hover,
.devices-groups-item>.devices-groups-item-edit:hover {
    opacity: 1;
}

.devices-groups-item-delete {
    pointer-events: auto;
    cursor: pointer;
    margin-right: 18px;
    margin-top: -16px;
    margin-bottom: -16px;
    position: absolute;
    right: 0px;
}

.devices-groups-item-delete>svg {
    height: 66px !important;
    width: 24px !important;
}

.devices-groups-item-edit-placeholder {
    height: 65px;
    width: 24px;
    margin-right: 10px;
    margin-top: -16px;
    margin-bottom: -16px;
}

.devices-groups-item-edit {
    pointer-events: auto;
    height: 65px;
    width: 24px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -16px;
    margin-bottom: -16px;
}

// ================================ END devices/groups list item 

.layers-thumbnail {
    object-fit: cover;
    width: 270px;
    max-height: 40px;
}

.layer-indicator {
    position: absolute;
    bottom: 0px;
    font-size: 10px !important;
    left: 20px;
    color: #00000069;
}

.controller-indicator {
    position: absolute;
    bottom: 0px;
    font-size: 10px !important;
    right: 50px;
    text-transform: uppercase;
}

.layers-canvas {
    // scroll-snap-align: center; // programmatically trigger it using jQuery
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #d8dbe0;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.layout-canvas-dimension-label {
    //     font-size: 11px;
    //     text-shadow: .5px .5px 0px white;
    margin-left: -50px;
}

// .layers-canvas>div>div {
//     cursor: pointer;
// }

// .layers-canvas>div>div:hover,
.plotted-layers-active-marker {
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 2px dashed white !important;
}

.plotted-layers:hover .plotted-layers-active-marker,
.plotted-layers-active {
    visibility: visible;
}

.plotted-layers-active {
    cursor: move;
}

.corner-indicator {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid rgb(122, 122, 122);
}

.top-left {
    top: -7px;
    left: -7px;
}

.top-right {
    top: -7px;
    right: -7px;
}

.bottom-left {
    bottom: -7px;
    left: -7px;
}

.bottom-right {
    bottom: -7px;
    right: -7px;
}

.plotted-devices-marker {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px dashed white !important;
}

.plotted-devices-marker>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 12px;
    text-shadow: .5px .5px 0px white !important;

}

.plotted-devices-marker-active {
    background-color: rgb(244 255 53 / 50%);
}

.layout-slider {
    background-color: rgba(255, 255, 255, 0.3);
    border-top: 1px solid #d8dbe0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // z-index: 1039; // modal backdrop = 1040 
    // text-shadow: .5px .5px 0px white !important;
}

.layout-slider>div>span {
    color: black;
    text-shadow: 1px 1px 0.5px #fff;
}

.slider-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.layout-slider>div {
    margin: 0px 7px;
}

.layout-slider .badge {
    color: white;
    text-shadow: none;
    padding: 6px 12px;
    text-transform: capitalize;
    font-weight: 500;
}

.select-type-container {
    margin-top: -5px;
    margin-bottom: -5px;
    display: flex;
    align-items: center;
}

.layers-select-type {
    min-width: 180px
}

.layer-thumbnail-container {
    position: relative;
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
}

.layer-thumbnail-container.disabled span,
.layer-thumbnail-container.disabled small {
    color: rgb(199, 199, 199) !important;
}

.layer-thumbnail-container:not(.disabled):hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
}

.add-layer-thumbnail-name {
    display: block;
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
}

.layout-properties {
    margin-top: 10px;
}

.device-list-container {
    position: relative;
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
    display: flex;
    justify-content: space-between;
}

.has-changes-badge {
    position: absolute;
    font-size: 8px;
    padding: 4px 8px !important;
    margin-left: -60px !important;
    margin-top: -15px;
}

.device-list-container:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
}

//breadcrumb highlight color
.highlight-blue {
    color: #0f83aa;
    cursor: pointer;
    text-shadow: 1px 1px 0 #fff;
}

.breadcrumb-nav {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    margin-bottom: 20px;
}

.playlist-item-span {
    font-family: Roboto !important;
}

.span-animation {
    animation: marquee 10s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}