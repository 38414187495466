.c-main-container {
    // z-index: 2;
}

@media (min-width: 993px) {
    .c-main-container {
        z-index: 2;
    }
}


.c-header {
    z-index: 3;
}

.c-footer {
    // z-index: 0;
}

.circles {
    z-index: 1;
}

// modal
.behind-another-modal {
    // z-index: 1039;
    // modal's backdrop z-index is 1040
}

// use this instead of c-wrapper z-index
.c-wrapper>.c-body>.c-main>.container-fluid {
    // z-index: 1040;
    // position: relative;
}

.c-sidebar {
    // z-index: 1041 !important;
}

.c-sidebar-backdrop {
    // z-index: 1040;
}

.c-sidebar-nav,
.c-sidebar-minimizer {
    // z-index: 1041;
}

.c-header.c-header-fixed {
    // z-index: 1038;
}

.c-switch-slider {
    // z-index: 0 !important;
}

#landing-page .container {
    z-index: 2;
}

// sticky
.sticky-site-header {
    position: -webkit-sticky;
    position: sticky;
    top: 57px;
    z-index: 1000; // need 1k for side nav item on hover and not 1039,  modal backdrop 1040
}

.tox-tinymce-aux {
    z-index: 999 !important;
}

.global-react-select__menu {
    z-index: 2 !important;
}

.pac-container {
    z-index: 1051; // on top of modal
}